import React , { ReactElement } from 'react';
import styles from './TicketsBundlesCard.module.scss';
import LinesEllipsis from 'react-lines-ellipsis';
import { useOrientations } from '@common/hooks/useOrientation';
import { ISrcSet, Picture } from '@kursk/components/ui/Picture/Picture';
import Link from 'next/link';
import { ITicketsBundles } from "@common/redux/types/ticketsBundles";
import moment from "moment";
import { getPriceTitle } from '@kursk/utils/getPriceTitle';

interface ITicketsBundlesCardProps {
  ticketsBundles: ITicketsBundles;
}

export function TicketsBundlesCard({ ticketsBundles }: ITicketsBundlesCardProps): ReactElement {
  const orientation = useOrientations();
  const imgSrcSet: ISrcSet[] = [
    {
      media: '(min-width: 768px)',
      width: 290,
      height: 360,
      cropMode: 'noCrop',
    },
    {
      media: '(max-width: 767px)',
      width: 335,
      height: 410,
      cropMode: 'noCrop',
    },
  ];

  const getPriceInfo = (): string | JSX.Element => {
    if (ticketsBundles.maxPrice === 0 && ticketsBundles.minPrice === 0) {
      return 'Бесплатно';
    }

    if (ticketsBundles.minPrice && ticketsBundles.maxPrice) {
      return getPriceTitle(ticketsBundles.minPrice, ticketsBundles.maxPrice);
    }

    return '';
  }


  return (
    <Link href={`/ticketsbundles/${ticketsBundles._id}/${ticketsBundles.sysName}`}>
      <a>
        <div className={styles.wrapper}>
          <Picture className={styles.image} srcSet={imgSrcSet} image={ticketsBundles.image} />
          <div className={styles.info}>
            <div className={styles.infoHeader}>
              <div>
                {(ticketsBundles.ageRestriction || ticketsBundles.ageRestriction === 0) && (
                  <div className={styles.age}>
                    {ticketsBundles.ageRestriction}+
                  </div>
                )}
              </div>
              <div className={styles.badge}>Абонемент</div>
            </div>
            <div className={styles.expire}>
              {`Истекает ${moment(ticketsBundles.end).format('D MMMM')}`}
            </div>
            <LinesEllipsis
              text={ticketsBundles.name}
              maxLine={orientation === 'mobile' ? 2 : 3}
              ellipsis='...'
              trimRight
              basedOn='letters'
              className={styles.title}
            />
            <LinesEllipsis
              text={ticketsBundles.description}
              maxLine='3'
              ellipsis='...'
              trimRight
              basedOn='letters'
              className={styles.description}
            />
            <div className={styles.priceWrapper}>
              <div className={styles.priceDescription}>
                {`Цена абонемента из ${ticketsBundles.batches.length} ${ticketsBundles.batches.length === 1 ? 'билета'
                  : 'билетов'}`}
              </div>
              <div className={styles.priceInfo}>
                {getPriceInfo()}
              </div>
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
}
