import React, { ReactElement } from 'react';
import Link from 'next/link';
import LinesEllipsis from 'react-lines-ellipsis/lib/loose';
import styles from './ManualCard.module.scss';
import { ISrcSet, Picture } from "@kursk/components/ui/Picture/Picture";
import { IManual } from "@common/redux/types/picks";

interface IManualCardProps {
  manual: IManual;
}

export function ManualCard({ manual }: IManualCardProps): ReactElement {

  const imgSrcSet: ISrcSet[] = [
    {
      media: '(min-width: 0px)',
      width: 270,
      height: 241,
      cropMode: 'noCrop',
    }
  ]

  return (
    <Link href={manual.url} passHref>
      <a target='_blank' rel="noreferrer">
        <div
          className={styles.wrapper}
        >
          <Picture className={styles.image} srcSet={imgSrcSet} image={manual.image}/>
          <div className={styles.info}>
            <LinesEllipsis
              text={manual.name}
              maxLine={3}
              ellipsis='...'
              trimRight
              basedOn='letters'
              className={styles.title}
            />
            <LinesEllipsis
              text={manual.buttonText}
              maxLine={1}
              ellipsis=' (...'
              trimRight
              basedOn='letters'
              className={styles.text}
            />
          </div>
        </div>
      </a>
    </Link>
  );
}
