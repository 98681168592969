import React , { ReactElement } from 'react';
import Link from 'next/link';
import { IRestaurantPick } from '@common/redux/types/picks';
import styles from './Restaurants.module.scss';
import { RestaurantCard } from './RestaurantCard/RestaurantCard';
import { CarouselButtonGroup } from '@kursk/components/ui/CarouselButtonGroup/CarouselButtonGroup';
import { Carousel, ICarouselOptions } from '@kursk/components/ui/Carousel/Carousel';
import { ArrowRightIcon } from '@kursk/components/ui/icons';
import { IRestaurant } from "@common/redux/types/places";
import { indexPageResponsive } from "@kursk/components/pages/Home/indexPage.conts";
import { themeValue } from "@kursk/themes";
import { BelDownArrowIcon } from "@kursk/components/ui/icons.bel";

interface IRestaurantsProps {
  restaurants: IRestaurantPick[];
}

export function Restaurants({ restaurants }: IRestaurantsProps): ReactElement {
  const renderCard = (item: IRestaurant) => <RestaurantCard key={item._id} restaurant={item} />

  const options: ICarouselOptions = {
    responsive: indexPageResponsive,
    swipeable: true,
    infinite: true,
    renderButtonGroupOutside: true,
    partialVisible: true
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h2 className={styles.title}>{themeValue({
          astrakhan: 'Где поесть',
          default: 'Рестораны',
        })}</h2>
        <Link href='/restaurants' passHref>
          <a>
            <button className={styles.button}>
              <div className={styles.buttonText}>Все рестораны</div>
              {themeValue({
                bel: <BelDownArrowIcon />,
                default: <ArrowRightIcon />
              })}
            </button>
          </a>
        </Link>
      </div>
      <div className={styles.cards}>
        <Carousel
          carouselOptions={options}
          Buttons={<CarouselButtonGroup />}
        >
          {restaurants.map(({ restaurant }) => restaurant).map(renderCard)}
        </Carousel>
      </div>
    </div>
  )
}
