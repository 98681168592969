import React, { ReactElement } from 'react';
import { Carousel, ICarouselOptions } from '../../../ui/Carousel/Carousel';
import { EventCard } from '../Events/EventCard/EventCard';
import { IBestPick } from "@common/redux/types/picks";
import { PlaceCard } from "@kursk/components/pages/Home/Places/PlaceCard/PlaceCard";
import { useOrientations } from "@common/hooks/useOrientation";
import { ManualCard } from "@kursk/components/ui/ManualCard/ManualCard";
import { EntityCard } from "@kursk/components/ui/EntityCard/EntityCard";
import { indexPageResponsiveSmallCard } from "@kursk/components/pages/Home/indexPage.conts";
import { themeValue } from '@kursk/themes';
import styles from './Best.module.scss';


interface IEventsProps {
  entity: IBestPick[];
}

export function Best({ entity }: IEventsProps): ReactElement {
  const orientation = useOrientations();

  const renderCard = (item: IBestPick) => {
    if (item.type === 'event') {
      return <EventCard key={item.event._id} event={item.event} type='small' theme='white'/>
    }

    if (item.type === 'article') {
      return <EntityCard key={item.article._id} entityType="overviews" entity={item.article} type='small'/>
    }

    if (item.type === 'manual') {
      return <ManualCard key={item.manual.name} manual={item.manual}/>
    }

    if (item.type === 'place') {
      return <PlaceCard key={item.place._id} place={item.place} type='small'/>
    }
  }

  const renderGridCard = (
    item: IBestPick, index: number
  ) => {
    if (item.type === 'event') {
      return <EventCard
        key={item.event._id}
        event={item.event}
        type={index > 3 ? 'medium' : 'small'}
        theme='white'/>
    }
    if (item.type === 'place') {
      return <PlaceCard key={item.place._id} place={item.place}/>
    }

    if (item.type === 'manual') {
      return <ManualCard key={item.manual.name} manual={item.manual}/>
    }

    if (item.type === 'article') {
      return <EntityCard key={item.article._id} entityType="overviews" entity={item.article}/>
    }
  }

  const isShowCarousel = (orientation !== 'desktop'
    && ((orientation === 'tablet' && entity.length > 2)
      || (orientation === 'mobile' && entity.length > 1)));

  const options: ICarouselOptions = {
    responsive: indexPageResponsiveSmallCard,
    swipeable: true,
    infinite: true,
    renderButtonGroupOutside: true,
    partialVisible: true
  };

  return (
    <>
      <div className={styles.header}>
        <h2 className={styles.title}>
          {themeValue({
            pprog: 'Новости',
            astrakhan: 'Обязательно посетите!',
            default: 'Не пропустите!',
          })}
        </h2>
      </div>
      {isShowCarousel && (<div className={styles.cardsCarousel}>
        <Carousel
          carouselOptions={options}
        >
          {entity.map(renderCard)}
        </Carousel>
      </div>)}
      {!isShowCarousel && (
        <div className={styles.cardsGrid}>
          {entity.map(renderGridCard)}
        </div>
      )}
    </>
  )
}
