import { BaseSubscribeForm } from "@kursk/components/ui/BaseSubscribeForm/BaseSubscribeForm";
import { useSelectSlice } from "@common/redux/selectors/useSelectSlice";
import {
  loadSubscribeStatus,
  subscribeThunk,
  subscriptionFormSlice
} from "@common/redux/slices/subscriptionForm.slice";
import { useDispatchThunk } from "@common/redux/dispatchThunk";
import React from "react";
import { useDispatch } from "react-redux";
import { getConfig } from "@root/config/config";

export const SubscribeFooterForm = () => {
  const subscription = useSelectSlice(subscriptionFormSlice);
  const dispatch = useDispatch();
  const dispatchThunk = useDispatchThunk();

  React.useEffect(() => {
    dispatch(loadSubscribeStatus({}))
  }, [subscription.status]);

  const handleSubmit = async (email: string) => {
    const res = await dispatchThunk(subscribeThunk({ email }));
    if (!res.error) {
      return true;
    }
  }
  return (subscription.status || getConfig().subscribeDisabled ? (
    <></>
  ) : (
    <BaseSubscribeForm subscriptionSuccess={subscription.status} setSubscriptionSuccess={handleSubmit}/>
  ));
}
