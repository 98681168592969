import React , { ReactElement } from 'react';
import Link from 'next/link';
import LinesEllipsis from 'react-lines-ellipsis';
import { IArticle } from '@common/redux/types/articles';
import { useOrientations } from '@common/hooks/useOrientation';
import { Picture, ISrcSet } from '../../../../ui/Picture/Picture';
import styles from './OverviewCard.module.scss';

interface IOverviewCardProps {
  overview: IArticle;
}

export function OverviewCard({ overview }: IOverviewCardProps): ReactElement {
  const orientation = useOrientations();
  const imgSrcSet: ISrcSet[] = [
    {
      media: '(min-width: 768px)',
      width: 290,
      height: 360,
      cropMode: 'noCrop',
    },
    {
      media: '(max-width: 767px)',
      width: 335,
      height: 410,
      cropMode: 'noCrop',
    },
  ];

  return (
    <Link href={`/overviews/${overview._id}/${overview.sysName}`}>
      <a>
        <div className={styles.wrapper}>
          <Picture className={styles.image} srcSet={imgSrcSet} image={overview.image} />
          <div className={styles.info}>
            <div className={styles.badge}>Обзор</div>
            <LinesEllipsis
              text={overview.name}
              maxLine={orientation === 'mobile' ? 2 : 4}
              ellipsis='...'
              trimRight
              basedOn='letters'
              className={styles.title}
            />
            <p className={styles.description}>
              {overview.description}
            </p>
          </div>
        </div>
      </a>
    </Link>
  );
}
