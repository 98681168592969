import React , { ReactElement } from 'react';
import Link from 'next/link';
import LinesEllipsis from 'react-lines-ellipsis';
import { IArticle } from '@common/redux/types/articles';
import { useOrientations } from '@common/hooks/useOrientation';
import { Picture, ISrcSet } from '../../../../ui/Picture/Picture';
import styles from './NewsCard.module.scss';

interface INewsCardProps {
  item: IArticle;
}

export function NewsCard({ item }: INewsCardProps): ReactElement {
  const orientation = useOrientations();
  const imgSrcSet: ISrcSet[] = [
    {
      media: '(min-width: 768px)',
      width: 290,
      height: 360,
      cropMode: 'noCrop',
    },
    {
      media: '(max-width: 767px)',
      width: 335,
      height: 410,
      cropMode: 'noCrop',
    },
  ];

  return (
    <Link href={`/news/${item._id}/${item.sysName}`}>
      <a>
        <div className={styles.wrapper}>
          <div className={styles.info}>
            <div className={styles.badge}>Новость</div>
            <LinesEllipsis
              text={item.name}
              maxLine={orientation === 'mobile' ? 2 : 4}
              ellipsis='...'
              trimRight
              basedOn='letters'
              className={styles.title}
            />
            <LinesEllipsis
              text={item.description}
              maxLine={orientation === 'mobile' ? 6 : 7}
              ellipsis='...'
              trimRight
              basedOn='letters'
              className={styles.description}
            />
          </div>
          <Picture className={styles.image} srcSet={imgSrcSet} image={item.image} />
        </div>
      </a>
    </Link>
  );
}
