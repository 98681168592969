import React from "react";
import { TicketIcon } from "@kursk/components/ui/icons";
import { chars } from "@common/utils/constants";

export const getPriceTitle = (minPrice: number, maxPrice: number): string | JSX.Element => {

  if (minPrice === 0 && maxPrice === 0) {
    return 'Бесплатно';
  }

  if (minPrice === maxPrice && (minPrice !== 0 && maxPrice !== 0)) {
    return (
      <>
        <TicketIcon />
        {`${Number(Math.floor(minPrice) / 100).toFixed(0)} ${chars.rouble}`}
      </>
    );
  }

  return (
    <>
      <TicketIcon />
      {`от ${Number(Math.floor(minPrice) / 100).toFixed(0)} ${chars.rouble}`}
    </>
  );
}
