import classNames from 'classnames';
import moment from 'moment';
import React, { ReactElement } from 'react';
import Link from 'next/link';
import LinesEllipsis from 'react-lines-ellipsis/lib/loose';
import { ISrcSet, Picture } from '@kursk/components/ui/Picture/Picture';
import style from './ExcursionCard.module.scss';
import { isTicketsInfoAvailable } from '@common/utils/tickets';
import _ from 'lodash';
import { getPriceTitle } from '@kursk/utils/getPriceTitle';
import { IExcursion } from '@common/redux/types/excursion';
import { formatMinutes } from '@kursk/utils/format';
import {
  GuideGoImage, TGuideGoImageProps
} from "@kursk/components/pages/Home/Excursions/ExcursionCard/components/GuideGoImage/GuideGoImage";
import { ISource } from "@common/redux/types/common";

interface IExcursionCardProps {
  excursion: IExcursion & { source?: 'guideGo' | ISource };
  customStyle?: {
    readonly [key: string]: string;
  }
  page?: 'catalog';
}

export function ExcursionCard({ excursion, customStyle, page }: IExcursionCardProps): ReactElement {
  const styles = customStyle || style;

  const imgSrcSet: ISrcSet[] = [
    {
      media: '(min-width: 0px)',
      width: 290,
      height: 360,
      cropMode: 'noCrop',
    }
  ];
  const getPriceInfo = (): string | JSX.Element => {
    if (excursion.isFree) {
      return 'Бесплатно';
    }

    const { all } = excursion?.actualSeances || {};
    const { schedules } = excursion;
    const now = moment();

    const allShedules = all?.length ? all : _.filter(schedules, ({ end }) => moment(end).isAfter(now));

    const availableTicketsInfos = _(allShedules).map('ticketsInfo')
      .filter((ticketsInfo) => isTicketsInfoAvailable(ticketsInfo))
      .value();

    const maxPrice = _(availableTicketsInfos).map('maxPrice').max();
    const minPrice = _(availableTicketsInfos).map('minPrice').min();

    if (!_.isUndefined(minPrice) && !_.isUndefined(maxPrice)) {
      return getPriceTitle(minPrice, maxPrice);
    }

    return '';
  }

  const priceInfo = getPriceInfo();
  return (
    <Link
      href={
        excursion.source !== 'guideGo' ?
          `/excursions/${excursion._id}/${excursion.sysName}` :
          `/excursions-guidego/${excursion._id}`
      }
    >
      <a>
        <div
          className={
            classNames(
              styles.wrapper,
              page === 'catalog' && styles.catalog
            )}
        >
          {excursion.source === 'guideGo' ?
            (
              <div className={styles.image}>
                <GuideGoImage {...excursion.image as unknown as TGuideGoImageProps} />
              </div>
            ) : (
              <Picture
                className={styles.image}
                srcSet={imgSrcSet}
                image={excursion.image}
              />
            )}
          <div className={styles.info}>
            <div className={styles.badge}>Экскурсия</div>
            <p className={styles.duration}>{formatMinutes(excursion.duration)}</p>
            <LinesEllipsis
              text={excursion.name}
              maxLine={5}
              ellipsis='...'
              trimRight
              basedOn='letters'
              className={styles.title}
            />
            <LinesEllipsis
              text={excursion.description}
              maxLine={3}
              ellipsis='...'
              trimRight
              basedOn='letters'
              className={styles.description}
            />
            {priceInfo && (
              <p className={styles.price}>{priceInfo}</p>
            )}
          </div>
        </div>
      </a>
    </Link>
  );
}
