import Head from 'next/head';
import React from 'react';
import { getData } from './utils';
import { IEvent, IWidget } from "@common/redux/types/event";
import { IOverview } from "@common/redux/types/articles";
import { IPlace, IRestaurant, IHotel } from "@common/redux/types/places";
import { IEventPick } from "@common/redux/types/picks";
import { getConfig } from "@root/config/config";

export type ISeoTypes = 'events' | 'articles' | 'places' | 'main' | 'hotels' | 'restaurants';

interface IProps {
  entity?: IEvent | IPlace | IRestaurant | IHotel | IOverview | IEventPick | IWidget,
  type: ISeoTypes
}

export const LdJson = ({ entity = null, type }: IProps) => {
  if (!getConfig().seo.ldJson.enabled) {
    return <></>;
  }
  let jsonData = null;
  try {
    jsonData = getData(entity, type)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  if (!jsonData) {
    return <></>;
  }
  return (
    <Head>
      <script type="application/ld+json">
        {JSON.stringify(jsonData)}
      </script>
    </Head>
  )
}
