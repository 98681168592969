import React , { ReactElement } from 'react';
import Link from 'next/link';
import { IArticle } from '@common/redux/types/articles';
import { Picture, ISrcSet } from '../../../../ui/Picture/Picture';
import LinesEllipsis from 'react-lines-ellipsis/lib/loose';
import { useOrientations } from '@common/hooks/useOrientation';
import styles from './RouteCard.module.scss';
import _ from 'lodash';
import { regionContent } from '@kursk/content/regionContent';
import { themeValue } from "@kursk/themes";

interface IRouteCardProps {
  route: IArticle;
}

export function RouteCard({ route }: IRouteCardProps): ReactElement {
  const orientation = useOrientations();
  const imgSrcSet: ISrcSet[] = [
    {
      media: '(min-width: 768px)',
      width: 290,
      height: 360,
      cropMode: 'noCrop',
    },
    {
      media: '(max-width: 767px)',
      width: 335,
      height: 410,
      cropMode: 'noCrop',
    },
  ];

  return (
    <Link href={`/touristroutes/${route._id}/${route.sysName}`}>
      <a>
        <div className={styles.wrapper}>
          <div className={styles.info}>
            <div className={styles.badge}>{_.capitalize(regionContent.entitiesNames.touristroutes.nominative)}</div>
            <LinesEllipsis
              text={route.name}
              maxLine={orientation === 'mobile' ? 2 : 4}
              ellipsis='...'
              trimRight
              basedOn='letters'
              className={styles.title}
            />
            <LinesEllipsis
              className={styles.description}
              text={route.description}
              maxLine={themeValue({ bel: orientation === 'mobile' ? 5 : 6, default: 8 })}
              ellipsis='...'
              trimRight
              basedOn='letters'
            />
          </div>
          <Picture className={styles.image} srcSet={imgSrcSet} image={route.image} />
        </div>
      </a>
    </Link>
  );
}
