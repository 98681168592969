import React, { ReactElement } from 'react';
import Link from 'next/link';
import { IEventPick } from '@common/redux/types/picks';
import { Carousel, ICarouselOptions } from '../../../ui/Carousel/Carousel';
import { ArrowRightIcon } from '../../../ui/icons';
import { BelDownArrowIcon } from '../../../ui/icons.bel';
import styles from './Events.module.scss';
import { EventCard } from './EventCard/EventCard';
import { themeValue } from "@kursk/themes";
import { LdJson } from "@kursk/components/seo/LdJson";
import { generateGridScheme } from "@kursk/utils/generateGridScheme";
import { indexPageResponsiveSmallCard } from "@kursk/components/pages/Home/indexPage.conts";

interface IEventsProps {
  events: IEventPick[];
}

export function Events({ events }: IEventsProps): ReactElement {
  const renderCard = (item: IEventPick) => <EventCard key={item.event._id} event={item.event} type='small'/>

  const renderDesktopGrid = () => {
    const gridScheme = generateGridScheme(events);
    return events.map((item, index) =>
      <EventCard key={item.event._id} event={item.event} type={gridScheme[index]}/>
    );
  };

  const renderLdJson = (item: IEventPick) => <LdJson entity={item.event} type={'events'} key={item.event._id}/>

  const options: ICarouselOptions = {
    responsive: indexPageResponsiveSmallCard,
    swipeable: true,
    infinite: true,
    renderButtonGroupOutside: true,
    partialVisible: true
  };

  return (
    <>
      <div className={styles.header}>
        <h2 className={styles.title}>События</h2>
        <Link href='/events' passHref>
          <a>
            <button className={styles.button}>
              <div className={styles.buttonText}>Все события</div>
              {themeValue({
                bel: <BelDownArrowIcon />,
                default: <ArrowRightIcon />
              })}
            </button>
          </a>
        </Link>
      </div>
      <div className={styles.cardsCarousel}>
        {events.map(renderLdJson)}
        <Carousel
          carouselOptions={options}
        >
          {events.map(renderCard)}
        </Carousel>
      </div>
      <div className={styles.cardsGrid}>
        {renderDesktopGrid()}
      </div>
    </>
  )
}
