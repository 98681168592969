import React, { ReactElement } from 'react';
import Link from 'next/link';
import { Carousel, ICarouselOptions } from '../../../ui/Carousel/Carousel';
import { ArrowRightIcon } from '../../../ui/icons';
import { BelDownArrowIcon } from '../../../ui/icons.bel';
import styles from './Excursions.module.scss';
import { ExcursionCard } from './ExcursionCard/ExcursionCard';
import { themeValue } from "@kursk/themes";
// import { LdJson } from "@kursk/components/seo/LdJson";
import { indexPageResponsive } from "@kursk/components/pages/Home/indexPage.conts";
import { IExcursion } from '@common/redux/types/excursion';
import { CarouselButtonGroup } from '@kursk/components/ui/CarouselButtonGroup/CarouselButtonGroup';

interface IExcursionsProps {
  excursions: IExcursion[];
}

export function Excursions({ excursions }: IExcursionsProps): ReactElement {
  const renderCard = (item: IExcursion) => <div className={styles.cardWrapper}>
    <ExcursionCard key={item._id} excursion={item} />
  </div>

  // const renderLdJson = (item: IExcursion) => (
  //   <LdJson
  //     entity={item.excursion}
  //     type={'excursions'}
  //     key={item._id}
  //   />
  // )

  const options: ICarouselOptions = {
    responsive: indexPageResponsive,
    swipeable: true,
    infinite: true,
    renderButtonGroupOutside: true,
    partialVisible: true
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h2 className={styles.title}>Экскурсии</h2>
        <Link href='/excursions' passHref>
          <a>
            <button className={styles.button}>
              <div className={styles.buttonText}>Все экскурсии</div>
              {themeValue({
                bel: <BelDownArrowIcon />,
                default: <ArrowRightIcon />
              })}
            </button>
          </a>
        </Link>
      </div>
      <div className={styles.cards}>
        <Carousel
          carouselOptions={options}
          Buttons={<CarouselButtonGroup />}
        >
          {excursions.map(renderCard)}
        </Carousel>
      </div>
    </div>
  )
}
