import React , { ReactElement } from 'react';
import styles from './HotelCard.module.scss';
import LinesEllipsis from 'react-lines-ellipsis';
import { useOrientations } from '@common/hooks/useOrientation';
import { ISrcSet, Picture } from '@kursk/components/ui/Picture/Picture';
import { IHotel } from "@common/redux/types/places";
import Link from 'next/link';
import { themeValue } from "@kursk/themes";

interface IHotelCardProps {
  hotel: IHotel;
}

export function HotelCard({ hotel }: IHotelCardProps): ReactElement {
  const orientation = useOrientations();
  const imgSrcSet: ISrcSet[] = [
    {
      media: '(min-width: 768px)',
      width: 290,
      height: 360,
      cropMode: 'noCrop',
    },
    {
      media: '(max-width: 767px)',
      width: 335,
      height: 410,
      cropMode: 'noCrop',
    },
  ];

  return (
    <Link href={`/hotels/${hotel._id}/${hotel.sysName}`}>
      <a>
        <div className={styles.wrapper}>
          <div className={styles.info}>
            <div className={styles.badge}>Гостиница</div>
            <LinesEllipsis
              text={hotel.name}
              maxLine={orientation === 'mobile' ? 2 : 4}
              ellipsis='...'
              trimRight
              basedOn='letters'
              className={styles.title}
            />
            <LinesEllipsis
              className={styles.description}
              text={hotel.description}
              maxLine={themeValue({ bel: orientation === 'mobile' ? 5 : 6, default: 8 })}
              ellipsis='...'
              trimRight
              basedOn='letters'
            />
          </div>
          <Picture className={styles.image} srcSet={imgSrcSet} image={hotel.image} />
        </div>
      </a>
    </Link>
  );
}
