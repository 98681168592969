import React , { ReactElement } from 'react';
import Link from 'next/link';
import { Carousel, ICarouselOptions } from '../../../ui/Carousel/Carousel';
import { ArrowRightIcon } from '../../../ui/icons';
import styles from './News.module.scss';
import { NewsCard } from './NewsCard/NewsCard';
import { CarouselButtonGroup } from '@kursk/components/ui/CarouselButtonGroup/CarouselButtonGroup';
import { IArticle, INews } from "@common/redux/types/articles";
import { indexPageResponsive } from "@kursk/components/pages/Home/indexPage.conts";
import { themeValue } from "@kursk/themes";
import { BelDownArrowIcon } from "@kursk/components/ui/icons.bel";

interface INewsProps {
  news: INews[];
}

export function News({ news }: INewsProps): ReactElement {
  const renderCard = (item: IArticle) => <NewsCard key={item._id} item={item} />

  const options: ICarouselOptions = {
    responsive: indexPageResponsive,
    swipeable: true,
    infinite: true,
    renderButtonGroupOutside: true,
    partialVisible: true,
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h2 className={styles.title}>Новости</h2>
        <Link href='/news' passHref>
          <a>
            <button className={styles.button}>
              <div className={styles.buttonText}>Все новости</div>
              {themeValue({
                bel: <BelDownArrowIcon />,
                default: <ArrowRightIcon />
              })}
            </button>
          </a>
        </Link>
      </div>
      <div className={styles.cards}>
        <Carousel
          carouselOptions={options}
          Buttons={<CarouselButtonGroup />}
        >
          {news.map(renderCard)}
        </Carousel>
      </div>
    </div>
  )
}
