import React, { ReactElement } from 'react';
import Link from 'next/link';
import { Carousel, ICarouselOptions } from '../../../ui/Carousel/Carousel';
import { ArrowRightIcon } from '../../../ui/icons';
import styles from './Places.module.scss';
import { IPlacePick } from '@common/redux/types/picks';
import { PlaceCard } from './PlaceCard/PlaceCard';
import { themeValue } from "@kursk/themes";
import { BelDownArrowIcon } from "@kursk/components/ui/icons.bel";
import { generateGridScheme } from "@kursk/utils/generateGridScheme";
import { indexPageResponsiveSmallCard } from "@kursk/components/pages/Home/indexPage.conts";

interface IPlacesProps {
  places: IPlacePick[];
}

export function Places({ places }: IPlacesProps): ReactElement {
  const renderCard = (item: IPlacePick) => <PlaceCard key={item.place._id} place={item.place}/>;

  const renderDesktopGrid = () => {
    const gridScheme = generateGridScheme(places);
    return places.map((item, index) =>
      <PlaceCard key={item.place._id} place={item.place} type={gridScheme[index]}/>
    );
  };

  const options: ICarouselOptions = {
    responsive: indexPageResponsiveSmallCard,
    swipeable: true,
    infinite: true,
    renderButtonGroupOutside: true,
    partialVisible: true
  };

  const cards = places.map(renderCard);

  return (
    <>
      <div className={styles.header}>
        <h2 className={styles.title}>Места</h2>
        <Link href='/places' passHref>
          <a>
            <button className={styles.button}>
              <div className={styles.buttonText}>Все места</div>
              {themeValue({
                bel: <BelDownArrowIcon/>,
                default: <ArrowRightIcon/>
              })}
            </button>
          </a>
        </Link>
      </div>
      <div className={styles.cardsCarousel}>
        <Carousel carouselOptions={options}>
          {cards}
        </Carousel>
      </div>
      <div className={styles.cardsGrid}>
        {renderDesktopGrid()}
      </div>
    </>
  )
}
