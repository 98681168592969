import { ResponsiveType } from "react-multi-carousel";

export const indexPageResponsive: ResponsiveType = {
  desktop: {
    breakpoint: { max: 9999, min: 1200 },
    items: 2
  },
  tablet1: {
    breakpoint: { max: 1199, min: 1024 },
    items: 1,
    partialVisibilityGutter: 404
  },
  tablet2: {
    breakpoint: { max: 1023, min: 1000 },
    items: 1,
    partialVisibilityGutter: 380
  },
  tablet3: {
    breakpoint: { max: 999, min: 912 },
    items: 1,
    partialVisibilityGutter: 292
  },
  tablet4: {
    breakpoint: { max: 911, min: 820 },
    items: 1,
    partialVisibilityGutter: 200
  },
  tablet5: {
    breakpoint: { max: 819, min: 800 },
    items: 1,
    partialVisibilityGutter: 180
  },
  tablet6: {
    breakpoint: { max: 799, min: 768 },
    items: 1,
    partialVisibilityGutter: 148
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
  }
};

export const indexPageResponsiveSmallCard: ResponsiveType = {
  tablet1: {
    breakpoint: { max: 1199, min: 1024 },
    items: 3,
    partialVisibilityGutter: 18
  },
  tablet2: {
    breakpoint: { max: 1023, min: 1000 },
    items: 3,
    partialVisibilityGutter: 10
  },
  tablet3: {
    breakpoint: { max: 999, min: 912 },
    items: 2,
    partialVisibilityGutter: 126
  },
  tablet4: {
    breakpoint: { max: 911, min: 820 },
    items: 2,
    partialVisibilityGutter: 80
  },
  tablet5: {
    breakpoint: { max: 819, min: 800 },
    items: 2,
    partialVisibilityGutter: 70
  },
  tablet6: {
    breakpoint: { max: 799, min: 768 },
    items: 2,
    partialVisibilityGutter: 54
  },
  mobile1: {
    breakpoint: { max: 767, min: 740 },
    items: 2,
    partialVisibilityGutter: 60
  },
  mobile2: {
    breakpoint: { max: 739, min: 710 },
    items: 2,
    partialVisibilityGutter: 40
  },
  mobile3: {
    breakpoint: { max: 709, min: 670 },
    items: 2,
    partialVisibilityGutter: 25
  },
  mobile4: {
    breakpoint: { max: 669, min: 630 },
    items: 1,
    partialVisibilityGutter: 300
  },
  mobile5: {
    breakpoint: { max: 629, min: 590 },
    items: 1,
    partialVisibilityGutter: 260
  },
  mobile6: {
    breakpoint: { max: 589, min: 550 },
    items: 1,
    partialVisibilityGutter: 220
  },
  mobile7: {
    breakpoint: { max: 549, min: 510 },
    items: 1,
    partialVisibilityGutter: 180
  },
  mobile8: {
    breakpoint: { max: 509, min: 470 },
    items: 1,
    partialVisibilityGutter: 140
  },
  mobile9: {
    breakpoint: { max: 469, min: 414 },
    items: 1,
    partialVisibilityGutter: 84
  },
  mobile10: {
    breakpoint: { max: 413, min: 412 },
    items: 1,
    partialVisibilityGutter: 82
  },
  mobile11: {
    breakpoint: { max: 411, min: 390 },
    items: 1,
    partialVisibilityGutter: 60
  },
  mobile12: {
    breakpoint: { max: 389, min: 375 },
    items: 1,
    partialVisibilityGutter: 45
  },
  mobile13: {
    breakpoint: { max: 374, min: 360 },
    items: 1,
    partialVisibilityGutter: 30
  },
  mobile14: {
    breakpoint: { max: 359, min: 0 },
    items: 1,
  }
};
