import moment from 'moment';
import React, { ReactElement } from 'react';
import Link from 'next/link';
import LinesEllipsis from 'react-lines-ellipsis/lib/loose';
import { ISrcSet, Picture } from '@kursk/components/ui/Picture/Picture';
import style from './TourCard.module.scss';
import { isTicketsInfoAvailable } from '@common/utils/tickets';
import _ from 'lodash';
import { getPriceTitle } from '@kursk/utils/getPriceTitle';
import { ITour } from '@common/redux/types/tour';
import classNames from 'classnames';

interface ITourCardProps {
  tour: ITour;
  customStyle?: {
    readonly [key: string]: string;
  };
  page?: 'catalog';
}

export function TourCard({ tour, customStyle, page }: ITourCardProps): ReactElement {
  const styles = customStyle || style;

  const imgSrcSet: ISrcSet[] = [
    {
      media: '(min-width: 0px)',
      width: 290,
      height: 360,
      cropMode: 'noCrop',
    }
  ];
  const getPriceInfo = (): string | JSX.Element => {
    if (tour.isFree) {
      return 'Бесплатно';
    }

    const { all } = tour?.actualSeances || {};
    const { schedules } = tour;
    const now = moment();

    const allShedules = all?.length ? all : _.filter(schedules, ({ end }) => moment(end).isAfter(now));

    const availableTicketsInfos = _(allShedules).map('ticketsInfo')
      .filter((ticketsInfo) => isTicketsInfoAvailable(ticketsInfo))
      .value();

    const maxPrice = _(availableTicketsInfos).map('maxPrice').max();
    const minPrice = _(availableTicketsInfos).map('minPrice').min();

    if (!_.isUndefined(minPrice) && !_.isUndefined(maxPrice)) {
      return getPriceTitle(minPrice, maxPrice);
    }

    return '';
  }

  const priceInfo = getPriceInfo();
  return (
    <Link href={`/tours/${tour._id}/${tour.sysName}`}>

      <a>
        <div
          className={classNames(
            styles.wrapper,
            page === 'catalog' && styles.catalog
          )}
        >
          <Picture className={styles.image} srcSet={imgSrcSet} image={tour.image}/>
          <div className={styles.info}>
            <div className={styles.badge}>Tур</div>
            <p className={styles.duration}>{tour.tourProgram.length}-дневный тур</p>
            <LinesEllipsis
              text={tour.name}
              maxLine={5}
              ellipsis='...'
              trimRight
              basedOn='letters'
              className={styles.title}
            />
            <LinesEllipsis
              text={tour.description}
              maxLine={3}
              ellipsis='...'
              trimRight
              basedOn='letters'
              className={styles.description}
            />
            {priceInfo && (
              <p className={styles.price}>{priceInfo}</p>
            )}
          </div>
        </div>
      </a>
    </Link>
  );
}
