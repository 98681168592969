import React, { ReactElement } from 'react';
import Link from 'next/link';
import { Carousel, ICarouselOptions } from '../../../ui/Carousel/Carousel';
import { ArrowRightIcon } from '../../../ui/icons';
import { BelDownArrowIcon } from '../../../ui/icons.bel';
import styles from './Tours.module.scss';
import { TourCard } from './TourCard/TourCard';
import { themeValue } from "@kursk/themes";
// import { LdJson } from "@kursk/components/seo/LdJson";
import { indexPageResponsive } from "@kursk/components/pages/Home/indexPage.conts";
import { ITour } from '@common/redux/types/tour';
import { CarouselButtonGroup } from '@kursk/components/ui/CarouselButtonGroup/CarouselButtonGroup';

interface IToursProps {
  tours: ITour[];
}

export function Tours({ tours }: IToursProps): ReactElement {
  const renderCard = (item: ITour) => <div className={styles.cardWrapper}>
    <TourCard key={item._id} tour={item} />
  </div>

  // const renderLdJson = (item: ITour) => (
  //   <LdJson
  //     entity={item.tour}
  //     type={'tours'}
  //     key={item._id}
  //   />
  // )

  const options: ICarouselOptions = {
    responsive: indexPageResponsive,
    swipeable: true,
    infinite: true,
    renderButtonGroupOutside: true,
    partialVisible: true
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h2 className={styles.title}>Туры</h2>
        <Link href='/tours' passHref>
          <a>
            <button className={styles.button}>
              <div className={styles.buttonText}>Все туры</div>
              {themeValue({
                bel: <BelDownArrowIcon />,
                default: <ArrowRightIcon />
              })}
            </button>
          </a>
        </Link>
      </div>
      <div className={styles.cards}>
        <Carousel
          carouselOptions={options}
          Buttons={<CarouselButtonGroup />}
        >
          {tours.map(renderCard)}
        </Carousel>
      </div>
    </div>
  )
}
