/* Форматирование времени.
 *
 * Преобразование минут в часы + минуты
 */
export const formatMinutes = (value: number): string => {
  const hours = Math.floor(value / 60);
  const minutes = value % 60;

  if (hours && minutes) {
    return `${hours} ч ${minutes} мин`;
  }

  if (hours) {
    return `${hours} ч`;
  }

  return `${minutes} мин`;
};
