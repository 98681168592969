import React , { ReactElement } from 'react';
import Link from 'next/link';
import { ITicketsBundlesPick } from '@common/redux/types/picks';
import styles from './TicketsBundles.module.scss';
import { CarouselButtonGroup } from '@kursk/components/ui/CarouselButtonGroup/CarouselButtonGroup';
import { TicketsBundlesCard } from './TicketsBundlesCard/TicketsBundlesCard';
import { ArrowRightIcon } from '@kursk/components/ui/icons';
import { Carousel, ICarouselOptions } from '@kursk/components/ui/Carousel/Carousel';
import { indexPageResponsive } from "@kursk/components/pages/Home/indexPage.conts";
import { ITicketsBundles } from "@common/redux/types/ticketsBundles";
import { themeValue } from "@kursk/themes";
import { BelDownArrowIcon } from "@kursk/components/ui/icons.bel";

interface ITicketsBundlesProps {
  ticketsBundles: ITicketsBundlesPick[];
}

export function TicketsBundles({ ticketsBundles }: ITicketsBundlesProps): ReactElement {
  const renderCard = (item: ITicketsBundles) => <TicketsBundlesCard key={item._id} ticketsBundles={item} />

  const options: ICarouselOptions = {
    responsive: indexPageResponsive,
    swipeable: true,
    infinite: true,
    renderButtonGroupOutside: true,
    partialVisible: true
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h2 className={styles.title}>Абонементы</h2>
        <Link href='/ticketsbundles' passHref>
          <a>
            <button className={styles.button}>
              <div className={styles.buttonText}>ВСЕ абонементы</div>
              {themeValue({
                bel: <BelDownArrowIcon />,
                default: <ArrowRightIcon />
              })}
            </button>
          </a>
        </Link>
      </div>
      <div className={styles.cards}>
        <Carousel
          carouselOptions={options}
          Buttons={<CarouselButtonGroup />}
        >
          {ticketsBundles.map(({ ticketsBundle }) => ticketsBundle).map(renderCard)}
        </Carousel>
      </div>
    </div>
  )
}
