import React , { ReactElement } from 'react';
import Link from 'next/link';
import { ITouristRoute } from '@common/redux/types/picks';
import { Carousel, ICarouselOptions } from '../../../ui/Carousel/Carousel';
import { ArrowRightIcon } from '../../../ui/icons';
import { CarouselButtonGroup } from '@kursk/components/ui/CarouselButtonGroup/CarouselButtonGroup';
import { RouteCard } from './RouteCard/RouteCard';
import styles from './Routes.module.scss';
import { IArticle } from "@common/redux/types/articles";
import { indexPageResponsive } from "@kursk/components/pages/Home/indexPage.conts";
import { regionContent } from '@kursk/content/regionContent';
import { themeValue } from "@kursk/themes";
import { BelDownArrowIcon } from "@kursk/components/ui/icons.bel";

interface RoutesProps {
  routes: ITouristRoute[];
}

export function Routes({ routes }: RoutesProps): ReactElement {
  const renderCard = (item: IArticle) => <RouteCard key={item._id} route={item} />

  const options: ICarouselOptions = {
    responsive: indexPageResponsive,
    swipeable: true,
    infinite: true,
    renderButtonGroupOutside: true,
    partialVisible: true
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h2 className={styles.title}>{regionContent.entitiesNames.touristroutes.plural}</h2>
        <Link href='/touristroutes' passHref>
          <a>
            <button className={styles.button}>
              <div className={styles.buttonText}>Все {regionContent.entitiesNames.touristroutes.plural}</div>
              {themeValue({
                bel: <BelDownArrowIcon />,
                default: <ArrowRightIcon />
              })}
            </button>
          </a>
        </Link>
      </div>
      <div className={styles.cards}>
        <Carousel
          carouselOptions={options}
          Buttons={<CarouselButtonGroup />}
        >
          {routes.map(({ touristRoute }) => touristRoute).map(renderCard)}
        </Carousel>
      </div>
    </div>
  )
}
