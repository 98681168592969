import React , { ReactElement } from 'react';
import Link from 'next/link';
import { IHotelPick, } from '@common/redux/types/picks';
import styles from './Hotels.module.scss';
import { CarouselButtonGroup } from '@kursk/components/ui/CarouselButtonGroup/CarouselButtonGroup';
import { HotelCard } from './HotelCard/HotelCard';
import { ArrowRightIcon } from '@kursk/components/ui/icons';
import { Carousel, ICarouselOptions } from '@kursk/components/ui/Carousel/Carousel';
import { IHotel } from "@common/redux/types/places";
import { indexPageResponsive } from "@kursk/components/pages/Home/indexPage.conts";
import { themeValue } from "@kursk/themes";
import { BelDownArrowIcon } from "@kursk/components/ui/icons.bel";

interface IHotelsProps {
  hotels: IHotelPick[];
}

export function Hotels({ hotels }: IHotelsProps): ReactElement {
  const renderCard = (item: IHotel) => <HotelCard key={item._id} hotel={item} />

  const options: ICarouselOptions = {
    responsive: indexPageResponsive,
    swipeable: true,
    infinite: true,
    renderButtonGroupOutside: true,
    partialVisible: true
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h2 className={styles.title}>Гостиницы</h2>
        <Link href='/hotels' passHref>
          <a>
            <button className={styles.button}>
              <div className={styles.buttonText}>Все гостиницы</div>
              {themeValue({
                bel: <BelDownArrowIcon />,
                default: <ArrowRightIcon />
              })}
            </button>
          </a>
        </Link>
      </div>
      <div className={styles.cards}>
        <Carousel
          carouselOptions={options}
          Buttons={<CarouselButtonGroup />}
        >
          {hotels.map(({ hotel }) => hotel).map(renderCard)}
        </Carousel>
      </div>
    </div>
  )
}
