import React , { ReactElement } from 'react';
import Link from 'next/link';
import { IArticlePick } from '@common/redux/types/picks';
import { Carousel, ICarouselOptions } from '../../../ui/Carousel/Carousel';
import { ArrowRightIcon } from '../../../ui/icons';
import styles from './Overviews.module.scss';
import { OverviewCard } from './OverviewCard/OverviewCard';
import { CarouselButtonGroup } from '@kursk/components/ui/CarouselButtonGroup/CarouselButtonGroup';
import { IArticle } from "@common/redux/types/articles";
import { indexPageResponsive } from "@kursk/components/pages/Home/indexPage.conts";
import { themeValue } from "@kursk/themes";
import { BelDownArrowIcon } from "@kursk/components/ui/icons.bel";

interface IOverviewsProps {
  overviews: IArticlePick[];
}

export function Overviews({ overviews }: IOverviewsProps): ReactElement {
  const renderCard = (item: IArticle) => <OverviewCard key={item._id} overview={item} />

  const options: ICarouselOptions = {
    responsive: indexPageResponsive,
    swipeable: true,
    infinite: true,
    renderButtonGroupOutside: true,
    partialVisible: true,
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h2 className={styles.title}>Обзоры</h2>
        <Link href='/overviews' passHref>
          <a>
            <button className={styles.button}>
              <div className={styles.buttonText}>Все обзоры</div>
              {themeValue({
                bel: <BelDownArrowIcon />,
                default: <ArrowRightIcon />
              })}
            </button>
          </a>
        </Link>
      </div>
      <div className={styles.cards}>
        <Carousel
          carouselOptions={options}
          Buttons={<CarouselButtonGroup />}
        >
          {overviews.map(({ article }) => article).map(renderCard)}
        </Carousel>
      </div>
    </div>
  )
}
