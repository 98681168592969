import classNames from 'classnames';
import React, { ReactElement } from 'react';
import { CarouselArrow } from '@kursk/components/ui/Carousel/Arrows/CarouselArrow';
import { ICustomButtonGroupProps } from '@kursk/components/ui/Carousel/Buttons/ButtonGroup';
import styles from './CarouselButtonGroup.module.scss';

export function CarouselButtonGroup(
  { next, previous, className, buttonClassName }: ICustomButtonGroupProps
): ReactElement {
  return (
    <div className={classNames(styles.wrapper, "carousel-button-group", className && className)}>
      <div className={styles.buttonGroup}>
        <CarouselArrow
          className={classNames(styles.arrow, buttonClassName && buttonClassName)}
          direction='left'
          onClick={() => previous()}
        />
        <CarouselArrow
          className={classNames(styles.arrow, buttonClassName && buttonClassName)}
          direction='right'
          onClick={() => next()}
        />
      </div>
    </div>
  );
}
